<template>
  <!-- 我的考试 -->
  <div class="quesTion">
    <kind-choose-view
      :showKc="true"
      :type="7"
      @isOk="isOk"
      :testPaperType="testPaperType"
    />
    <div class="qustionList">
      <div class="llist">
        <div
          v-if="list && list.length > 0 && search.examType == 1"
          class="llist"
        >
          <div class="item" v-for="(item, index) in list" :key="index">
            <div class="itemmsg">
              <div class="itemimg">
                <img src="@/assets/img/homesixth/Group 3623.png" alt="" />
              </div>
              <div class="itmsg">
                <div>{{ item.name }}</div>
                <div>
                  共{{
                    item.paperNameList ? item.paperNameList.length : 0
                  }}套试卷
                </div>
                <!-- <div>
                  <span class="price-icon">￥</span>{{ item.packagePrice }}
                </div> -->
                <div v-if="item.status == 2">
                  <span class="price-icon">￥</span>{{ item.packagePrice }}
                </div>
                <div v-if="item.status == 1">已购买</div>
              </div>
            </div>
            <div @click="goPage(item)" class="looktest">查看试卷</div>
          </div>
        </div>
        <div
          v-if="list && list.length > 0 && search.examType == 2"
          class="llists"
        >
          <div class="item" v-for="(item, index) in list" :key="index">
            <div class="itemmsg">
              <div class="itemimg">
                <img src="@/assets/img/homesixth/Group 3188 (1).png" alt="" />
              </div>
              <div class="itmsg">
                <div>{{ item.name }}</div>
                <div>
                  {{ item.typeName }}
                </div>
                <!-- <div>
                  <span class="price-icon">￥</span>{{ item.paperPrice }}
                </div> -->
                <div v-if="item.status == 2">
                  <span class="price-icon">￥</span>{{ item.paperPrice }}
                </div>
                <div v-if="item.status == 1">已购买</div>
              </div>
            </div>
            <div
              v-if="item.status == 2"
              @click="goOrder(12, item)"
              class="looktest"
            >
              立即购买
            </div>
            <div v-else class="looktest" @click="goLearn(item, true)">
              马上做题
            </div>
          </div>
        </div>
        <div class="nothing" v-if="!list || list.length == 0">
          <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
          <div class="nothing-text">暂无内容哦~</div>
        </div>
        <el-pagination
          class=""
          :total="total"
          :current-page="pageNum"
          :page-sizes="[6, 12, 18, 24]"
          :page-size="pageSize"
          :pager-count="3"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
      <div class="rlist">
        <div class="tuijian">
          <img src="@/assets/img/homesixth/Group 3611.png" alt="" />为您推荐
        </div>
        <div class="line"></div>
        <div
          class="tmsg"
          v-if="list && list.length > 0 && search.examType == 1"
        >
          <div
            @click="goPage(item)"
            v-for="(item, index) in list"
            v-if="index < 6"
            :key="index"
          >
            <div>{{ index + 1 }}</div>
            <span> {{ item.name }}</span>
          </div>
        </div>
        <div
          class="tmsg"
          v-if="list && list.length > 0 && search.examType == 2"
        >
          <div
            @click="goOrder(12, item)"
            v-for="(item, index) in list"
            :key="index"
            v-if="index < 6"
          >
            <div>{{ index + 1 }}</div>
            <span> {{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 暂无课程 -->
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getInfo, getToken } from "@/api/cookies";
import KindChooseView from "@/components/kindChooseViewsixthtk.vue";
import { OrderClient } from "@/api/orderClient";
let orderClient = new OrderClient();
import { AnswerClient } from "@/api/task/answerClient";
let answerClient = new AnswerClient();
export default {
  components: { KindChooseView },

  data() {
    return {
      list: [], //查询列表
      getInfo: null,
      search: {
        examType: 1,
      },
      pageSize: 6,
      pageNum: 1,
      total: 0,

      /* 考点讲解显示 */
      ifPlace: false,
      thisList: null,

      testPaperType: null,
    };
  },
  async created() {
    this.testPaperType = this.$route.query.type;
    this.userInfo = getInfo();
    // this.getList();
  },
  methods: {
    /* 获取列表 */
    getList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.1)",
      });
      if (this.search.type == 4002) {
        /* 考点练习 */
        answerClient
          .getPcExamPlaceList(
            this.pageSize,
            this.pageNum,
            this.search.kind ? this.search.kind : undefined,
            this.search.zyId ? this.search.zyId : undefined,
            undefined,
            undefined,
            this.userInfo ? this.userInfo.id : undefined
          )
          .then((res) => {
            this.list = res.rows;
            this.total = res.total;
            loading.close();
          });
      } else {
        if (this.search.examType == 1) {
          /* 试卷包 */
          answerClient
            .selectPageList(
              this.pageSize,
              this.pageNum,
              this.search.kind ? this.search.kind : undefined,
              this.search.zyId ? this.search.zyId : undefined,
              this.search.type ? this.search.type : undefined,
              this.userInfo ? this.userInfo.id : undefined,
              this.search.kcId ? this.search.kcId : undefined,
              this.search.name ? this.search.name : undefined
            )
            .then((res) => {
              if (res.code) {
                this.list = [];
                this.total = 0;
              } else {
                this.list = res.rows;
                this.total = res.total;
              }
              loading.close();
            });
        } else {
          /* 试卷 */
          answerClient
            .getQuestionPaperList(
              this.pageSize,
              this.pageNum,
              this.search.kind ? this.search.kind : undefined,
              this.search.zyId ? this.search.zyId : undefined,
              this.search.type ? this.search.type : undefined,
              this.userInfo ? this.userInfo.id : undefined,
              this.search.kcId ? this.search.kcId : undefined,
              this.search.name ? this.search.name : undefined
            )
            .then((res) => {
              if (res.code) {
                this.list = [];
                this.total = 0;
              } else {
                this.list = res.rows;
                this.total = res.total;
              }
              loading.close();
            });
        }
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    /* --------------------搜索--------------------------------- */
    // 筛选
    isOk(val) {
      this.search = val;
      this.searchClick();
    },
    /* 搜索 */
    searchClick() {
      this.pageNum = 1;
      this.list = [];
      this.total = 0;
      this.getList();
    },
    /* --------------------购买--------------------------------- */

    // 立即购买
    goOrder(type, item) {
      let token = getToken();
      if (token) {
        orderClient
          .addOrders(
            item.id,
            localStorage.getItem("userId"),
            item.name,
            type,
            item.name,
            item.kind
          )
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: "个人中心订单页",
                params: { orderNumber: res.msg },
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        Vue.prototype.goLoginView(true);
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
    /* 试卷bao做题 */
    goPage(item) {
      if (!this.userInfo) {
        this.$notify({
          title: "错误",
          message: "未登录，无法做题, 请先去登录",
          type: "error",
          duration: 2000,
        });
        return;
      }
      this.$router.push(`/all/examPack?packId=${item.id}`);
    },
    /* 试卷做题 */
    /* 做题 */
    goLearn(item, ifHave) {
      Vue.prototype.goExam(item.name, item.id, 4, 12, ifHave, item.seeNum);
    },
    /* 进入考点*/
    goPlace(item) {
      if (!this.userInfo) {
        Vue.prototype.goLoginView;
        return;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.qustionList {
  width: 1220px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 10px;
  .llist {
    width: 820px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    .item {
      width: 756px;
      height: 132px;
      border-bottom: 1px solid #ececec;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .itemmsg {
        width: 403px;
        display: flex;
        .itemimg {
          width: 48px;
          height: 56px;
          margin-top: 24px;
          img {
            width: 48px;
            height: 56px;
          }
        }
        .itmsg {
          > div:nth-child(1) {
            width: 500px;
            font-size: 16px;
            height: 16px;
            line-height: 16px;
            overflow: hidden;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-top: 24px;
            margin-left: 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          > div:nth-child(2) {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-top: 12px;
            margin-left: 12px;
          }
          > div:nth-child(3) {
            font-size: 16px;
            font-family: DIN-Bold;
            font-weight: 400;
            color: #ff5e51;
            margin-top: 28px;
            margin-left: 12px;
            span {
              font-size: 12px;
            }
          }
        }
      }
      .looktest {
        width: 80px;
        height: 28px;
        background: #ffffff;
        border-radius: 14px 14px 14px 14px;
        opacity: 1;
        border: 1px solid #1061ff;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #1061ff;
        line-height: 28px;
        text-align: center;
        margin-top: 52px;
        cursor: pointer;
      }
    }
    .item:nth-child(1) {
      margin-top: 10px;
    }
  }
  .llists {
    width: 820px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    .item {
      width: 756px;
      height: 148px;
      border-bottom: 1px solid #ececec;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .itemmsg {
        width: 403px;
        display: flex;
        .itemimg {
          width: 48px;
          height: 56px;
          margin-top: 24px;
          img {
            width: 48px;
            height: 56px;
          }
        }
        .itmsg {
          > div:nth-child(1) {
            width: 343px;
            font-size: 16px;
            height: 16px;
            line-height: 16px;
            overflow: hidden;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-top: 32px;
            margin-left: 12px;
          }
          > div:nth-child(2) {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-top: 12px;
            margin-left: 12px;
          }
          > div:nth-child(3) {
            font-size: 16px;
            font-family: DIN-Bold;
            font-weight: 400;
            color: #ff5e51;
            margin-top: 28px;
            margin-left: 12px;
            span {
              font-size: 12px;
            }
          }
        }
      }
      .looktest {
        width: 80px;
        height: 28px;
        background: #ffffff;
        border-radius: 14px 14px 14px 14px;
        opacity: 1;
        border: 1px solid #1061ff;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #1061ff;
        line-height: 28px;
        text-align: center;
        margin-top: 52px;
        cursor: pointer;
      }
    }
    .item:nth-child(1) {
      margin-top: 10px;
    }
  }
  .rlist {
    width: 380px;
    height: 355px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    padding-left: 32px;
    padding-top: 24px;
    .tuijian {
      display: flex;
      font-size: 18px;
      height: 24px;
      line-height: 20px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      img {
        width: 20px;
        height: 24px;
        opacity: 1;
        margin-right: 8px;
      }
    }
    .line {
      width: 316px;
      height: 1px;
      background: #ececec;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      margin-top: 24px;
    }
    .tmsg {
      display: flex;
      flex-direction: column;
      > div {
        display: flex;
        width: 282px;
        height: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
        margin-top: 30px;
        cursor: pointer;
        div {
          width: 16px;
          height: 16px;
          background: #ff9051;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          line-height: 16px;
          text-align: center;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          margin-right: 8px;
        }
        span {
          width: 282px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      > div:nth-child(1) {
        margin-top: 21px;
      }

      > div:nth-child(4) {
        div {
          background: #999999;
        }
      }
      > div:nth-child(5) {
        div {
          background: #999999;
        }
      }
      > div:nth-child(6) {
        div {
          background: #999999;
        }
      }
    }
  }
}
/deep/ .el-pagination .active {
  background: #1061ff !important;
}
/deep/ .el-pagination {
  margin: 30px auto;
  width: 680px;
}
</style>
